import { withDependencies } from '@wix/thunderbolt-ioc'
import { IPageWillUnmountHandler, Experiments, ExperimentsSymbol } from '@wix/thunderbolt-symbols'
import { IPubsub } from './types'
import { PlatformPubsubSymbol } from './symbols'

export const PubsubCleanup = withDependencies(
	[PlatformPubsubSymbol, ExperimentsSymbol],
	(pubsubFeature: IPubsub, experiments: Experiments): IPageWillUnmountHandler => {
		return {
			pageWillUnmount() {
				experiments['specs.thunderbolt.cleanPubsubOnNavigation'] && pubsubFeature.clearListenersBesideStubs()
			},
		}
	}
)
